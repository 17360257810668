import { Link } from "@components/";
import React from "react";
import * as styles from "./index.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";

export type SingleRelatedPostProps = {
	title: string;
	link: string;
};

export type RelatedPostsProps = {
	posts: SingleRelatedPostProps[];
	defaultNum?: number;
	marginTop?: number;
};

export default function RelatedPosts({
	posts,
	defaultNum = 4,
	marginTop = 0,
}: RelatedPostsProps): JSX.Element {
	return (
		<div className={styles.container} style={{ marginTop: marginTop }}>
			<TypographyV2
				variant="HEADING_4"
				color="brand-500"
				style={{ marginBottom: 0 }}
			>
				Related posts
			</TypographyV2>
			{posts.slice(0, defaultNum).map((post) => {
				return (
					<div className={styles.link}>
						<div className={styles.icon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M4 12H20M20 12L14 6M20 12L14 18"
									stroke="#0728B6"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<Link to={post.link} internal={false}>
							{post.title}
						</Link>
					</div>
				);
			})}
		</div>
	);
}
