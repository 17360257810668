// extracted by mini-css-extract-plugin
export var BODY = "index-module--BODY--wY4lV";
export var BODY__newLayout = "index-module--BODY__newLayout--0cAf7";
export var BUTTON = "index-module--BUTTON--M78lK";
export var BUTTON__newLayout = "index-module--BUTTON__newLayout--yh35B";
export var CAPTION = "index-module--CAPTION--+9f2p";
export var CAPTION__newLayout = "index-module--CAPTION__newLayout--C9QtY";
export var DESCRIPTION = "index-module--DESCRIPTION--DgGIw";
export var DESCRIPTION__newLayout = "index-module--DESCRIPTION__newLayout--mQI+-";
export var FOOTER = "index-module--FOOTER--ORVyz";
export var FOOTERBOLD = "index-module--FOOTERBOLD--kLplB";
export var FOOTER__newLayout = "index-module--FOOTER__newLayout--diF3Y";
export var HEADER1 = "index-module--HEADER1--M4nrU";
export var HEADER1__newLayout = "index-module--HEADER1__newLayout--cqNfu";
export var HEADER2 = "index-module--HEADER2--nN59o";
export var HEADER2__newLayout = "index-module--HEADER2__newLayout--5QSAB";
export var HEADER3 = "index-module--HEADER3--ivakn";
export var HEADER3__newLayout = "index-module--HEADER3__newLayout--q7uy7";
export var HEADER4 = "index-module--HEADER4--c2Tak";
export var HEADER4__newLayout = "index-module--HEADER4__newLayout--zNpSP";
export var HERO = "index-module--HERO--W3KF-";
export var HERO__newLayout = "index-module--HERO__newLayout--8EWD1";
export var QUOTE = "index-module--QUOTE--S+7Rw";
export var SUBHEADERDESC = "index-module--SUBHEADERDESC--Iubw5";
export var SUBHEADERDESCBOLD = "index-module--SUBHEADERDESCBOLD--6cl0R";
export var SUBHEADERDESC__newLayout = "index-module--SUBHEADERDESC__newLayout--ZPyRc";
export var SUBTITLE = "index-module--SUBTITLE--0Vi4O";
export var SUBTITLE__newLayout = "index-module--SUBTITLE__newLayout--khBhU";
export var center = "index-module--center--eeSg1";
export var container = "index-module--container--BsZhP";
export var icon = "index-module--icon--KTyot";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--CAoqy";
export var link = "index-module--link--F4-S7";